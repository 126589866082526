import * as React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { LavaLamp } from "../assets/lavalamp.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedinIn, faGithub, faInstagram, faTwitter, faFacebook, faMastodon } from "@fortawesome/free-brands-svg-icons"
import ReactTypingEffect from 'react-typing-effect';
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from '@fortawesome/fontawesome-svg-core';
import About from "../components/about.js"
config.autoAddCss = false;

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Alexander Cafarella</title>
        <meta name="description" content="Professional Nerd and Network Engineer" />
      </Helmet>

      {/* simple index page with centred text and mobile view */}

      <div className="h-screen items-center justify-center py-16 bg-slate-200 dark:bg-slate-800 overflow-hidden flex flex-col">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center md:text-left leading-8 font-bold tracking-tight text-slate-800 dark:text-slate-200 md:text-4xl">
                Alexander Cafarella.
              </span>
            </h1>
            <p className="mt-8 text-xl text-center md:text-left text-slate-800 dark:text-slate-200 leading-8">
              I'm a professional&nbsp;
              <ReactTypingEffect
                text={["nerd.", "geek.", "hardware engineer.", "network engineer.", "cloud designer."]}
                speed={100}
                eraseSpeed={75}
                eraseDelay={1000}
                typingDelay={500}
                cursor="|"
                cursorClassName="text-red-500"
                className="text-slate-800 dark:text-slate-200"
              />
            </p>
          </div>


          {/* social links right side */}
          <div className="mt-8 justify-between md:flex">
            <div className="text-center md:text-left text-slate-800 dark:text-slate-200 pb-4 pr-0 md:pb-0 md:pr-6">
              <a href="https://meshplane.net"
                className="inline-block transition duration-150 ease-in-out underline underline-offset-auto text-slate-800 dark:text-slate-200 hover:text-slate-600 dark:hover:text-slate-400 align-middle font-medium text-lg">
                Meshplane
              </a>
            </div>
            <div className="text-center md:text-right text-slate-800 dark:text-slate-200">
              <a
                href="https://www.linkedin.com/in/alexandercafarella/"
                className="inline-block transition duration-150 ease-in-out hover:text-black dark:hover:text-slate-400"
              >
                <FontAwesomeIcon icon={faLinkedinIn} alt="LinkedIn" size="2x" />
              </a>
              <a
                href="https://github.com/cafarella"
                className="ml-6 inline-block transition duration-150 ease-in-out hover:text-black dark:hover:text-slate-400"
              >
                <FontAwesomeIcon icon={faGithub} alt="Github" size="2x" />
              </a>
              <a
                href="https://www.instagram.com/alexandercafarella/"
                className="ml-6 inline-block transition duration-150 ease-in-out hover:text-black dark:hover:text-slate-400"
              >
                <FontAwesomeIcon icon={faInstagram} alt="Instagram" size="2x" />
              </a>
              <a
                href="https://twitter.com/alexandercafa"
                className="ml-6 inline-block transition duration-150 ease-in-out hover:text-black dark:hover:text-slate-400"
              >
                <FontAwesomeIcon icon={faTwitter} alt="Twitter" size="2x" />
              </a>
              <a
                href="https://www.facebook.com/apcaf"
                className="ml-6 inline-block transition duration-150 ease-in-out hover:text-black dark:hover:text-slate-400"
              >
                <FontAwesomeIcon icon={faFacebook} alt="Facebook" size="2x" />
              </a>
              <a
                rel="me"
                href="https://parasocial.network/@alexander"
                className="ml-6 inline-block transition duration-150 ease-in-out hover:text-black dark:hover:text-slate-400"
              >
                <FontAwesomeIcon icon={faMastodon} alt="Mastodon" size="2x" />
              </a>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 flex justify-center mx-auto mb-4">
          <a href="#about" className="text-slate-800 dark:text-slate-200">
            <svg
              className="w-8 h-8 animate-bounce"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 14l-7 7m0 0l-7-7m7 7V3"
              />
            </svg>
          </a>
        </div>
      </div>
      <div name="about" className="bg-slate-100 dark:bg-slate-900">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg lg:max-w-6xl mx-auto py-16 lg:py-12">
            <h1>
              <span className="text-3xl text-center leading-8 font-bold tracking-tight text-slate-800 dark:text-slate-200 md:text-4xl">
                About Me
              </span>
            </h1>
            {/* card and timeline */}
            <div className="flex flex-col lg:flex-row lg:space-x-8 lg:space-y-0 space-y-8 py-12">
              <div className="flex flex-col space-y-8">
                <div className="flex flex-col space-y-6">
                  <div className="flex flex-col space-y-4">
                    <div className="text-xl font-semibold text-slate-900 dark:text-slate-100">Who I am</div>
                    <div className="text-lg text-slate-800 dark:text-slate-200">
                      I'm a professional nerd, hardware/network engineer, and
                      software developer. I have been working in the IT industry
                      since 2016. I have a passion for technology, open source
                      software, and the internet.
                    </div>
                  </div>
                  <div className="flex flex-col space-y-4">
                    <div className="text-xl font-semibold text-slate-900 dark:text-slate-100">What I do</div>
                    <div className="text-lg text-slate-800 dark:text-slate-200">
                      I work on a variety of projects, from networking and
                      infrastructure to software development. I also enjoy
                      tinkering with enterprise hardware and designing/building random gadgets.
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-col space-y-8">
            <div className="flex flex-col space-y-6">
              <div className="flex flex-col space-y-4">
                <div className="text-xl font-semibold text-slate-900 dark:text-slate-100">Where I've been</div>
                <div className="text-lg text-slate-800 dark:text-slate-200">
                  I've been working in the IT industry for a while now, mainly focusing on the education sector and
                  small businesses. I've also had the opportunity to work on a
                  variety of projects that focus on many different aspects of technology and engineering.
              </div>
            </div>
            <div className="flex flex-col space-y-4">
              <div className="text-xl font-semibold text-slate-900 dark:text-slate-100">Where I'm going</div>
              <div className="text-lg text-slate-800 dark:text-slate-200">
                I plan to continue working in the IT industry, as well as
                contributing to open source projects. I'm also looking to get
                involved with more projects that focus on privacy, security, and
                decentralization.
              </div>
            </div>
          </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bottom-0 flex justify-center mx-auto py-6 bg-slate-200 dark:bg-slate-800">
        <a href="#top" className="text-slate-800 dark:text-slate-200">
          <svg
            className="w-8 h-8 animate-bounce"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </a>
      </div> */}

      {/* <div className="bg-cover bg-center bg-no-repeat" style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1645088520336-62d94324e869?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format)`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
        height: `100vh`,
      }} /> */}
      {/* <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-6xl font-bold">Alexander Cafarella</h1>
        <p className="text-xl">
          Professional Nerd and Hardware/Network Engineer
        </p>
        <div className="flex flex-row justify-center">
          <a href="
          https://www.linkedin.com/in/alexandercafarella/"
            className="btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a href="https://github.com/cafarella"
            className="btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </div>
        
      </div> */}
      {/* </div> */}
    </>
  )
}

export default IndexPage